export type LandingLocation = 'main' | 'pest-control';

// note(jose): If we ever get past two landing pages, we'll need to change this to a more robust solution like context

export class LearningLandingLocationManager {
  private static readonly STORAGE_KEY = 'landing_source';

  static SetLandingLocation(location: LandingLocation): void {
    sessionStorage.setItem(this.STORAGE_KEY, location);
  }

  static GetLandingLocation(): LandingLocation {
    const storedLocation = sessionStorage.getItem(this.STORAGE_KEY);
    return (storedLocation as LandingLocation) || 'main';
  }

  static ClearLandingLocation(): void {
    sessionStorage.removeItem(this.STORAGE_KEY);
  }
}
