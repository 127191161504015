import { type FAQ } from '../../../FAQ';
import { FAQItem } from '../../../FAQ/FAQList';

export function LearningLandingFAQs() {
  return (
    <section className='w-full bg-black py-10 hidden sm:block'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
        <h2 className='text-4xl font-bold text-white text-center mb-12'>FAQ</h2>
        <div className='max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4'>
          {faqItems.map((item) => (
            <FAQItem key={item.question} faq={item} />
          ))}
        </div>
      </div>
    </section>
  );
}

const faqItems: FAQ[] = [
  {
    id: '1',
    question: 'Is your content generator actually free?',
    answer:
      'Yes! You can generate courses, experience them, and share them with your colleagues on our platform—all without needing a credit card or paying any upfront fees.',
  },
  {
    id: '2',
    question: 'Can I download your content using SCORM?',
    answer:
      'Yes! While generating, experiencing, and sharing content on the Luna Park platform is free, you’ll need a paid plan to download files in SCORM format. This allows you to explore and test the platform before committing to a subscription.',
  },
  {
    id: '3',
    question: 'Is your LMS free to use?',
    answer:
      'Yes! Our LMS features—including learning journeys, onboarding stacks, and basic analytics—are available without requiring a credit card or any upfront payment.',
  },
  {
    id: '4',
    question: 'What sources do you use to generate courses?',
    answer: ` 
        We rely on a vetted pool of world-class, publicly available resources including, but not limited to:

        • Harvard Business Review
        • Learning Guild
        • MIT OpenCourseWare
        • OpenStax
        • McKinsey Academy
        • World Economic Forum
        • Brandon Hall Group
        • Gartner
        • TED Talks
        • Additional university sources, research articles and whitepapers from reputable industry experts\n
        These ensure that the content generated is accurate, relevant, and insightful`,
  },
  {
    id: '5',
    question: 'Am I able to edit the content that you generate?',
    answer:
      "Yes! Every element of our content—whether it's a course, quiz, or game—is fully customizable. You can edit text, images, and other components to personalize the content for your organization.",
  },
  {
    id: '6',
    question: 'What happens to the content I upload?',
    answer:
      'Your source materials are only used temporarily to generate personalized content. Once the content is created, we do not store your files on our backend. All uploaded files remain private to you and are not retained by us in any form.',
  },
];
