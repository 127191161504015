import { Link, useNavigate } from '@remix-run/react';
import { useEffect } from 'react';

import { useLearningAnalytics } from '../../../../analytics/learning';
import { LunaLogo, LunaParkLogo } from '../../../icons/LogoIcon';
import { LearningLandingLocationManager } from '../LearningLandingLocationManager';

export type LandingPages = 'main' | 'pest-control';

type HeaderProps = {
  userIsntLoggedIn: boolean;
  source: LandingPages;
  lpLogoRedirect: string;
};

export function LearningLandingHeader(props: HeaderProps) {
  const { userIsntLoggedIn, source } = props;
  const analytics = useLearningAnalytics();
  const navigate = useNavigate();
  const isLunaBranded = source === 'main';

  useEffect(() => {
    if (source === 'main' || source === 'pest-control') {
      LearningLandingLocationManager.SetLandingLocation(source);
    }
  }, [source]);

  const handleBookDemo = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackLandingBookMeetingClicked({
      landingPage: source,
    });
    window.location.href =
      'https://lunapark.com/lp/schedule-your-meeting-training-tool';
  };

  const handleTryFree = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackTryForFreeClicked({
      landingPage: source,
    });
    navigate('/learning/assistant');
  };

  const handleAboutUs = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackAboutUsClicked({
      landingPage: source,
    });
    navigate('/learning/about');
  };

  const handlePricing = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackPricingClicked({
      landingPage: source,
    });
    navigate('/learning/pricing');
  };

  const renderButtons = () => {
    if (userIsntLoggedIn) {
      return (
        <div className='flex items-center justify-center gap-2'>
          <nav className='hidden sm:flex items-center gap-6 text-sms mr-2'>
            <Link
              to='/learning/about'
              className='text-white hover:text-gray-300 font-medium'
              onClick={handleAboutUs}
            >
              About Us
            </Link>
            <Link
              to='/learning/pricing'
              className='text-white hover:text-gray-300 font-medium'
              onClick={handlePricing}
            >
              Pricing
            </Link>
          </nav>
          <a
            href='https://lunapark.com/lp/schedule-your-meeting-training-tool'
            onClick={handleBookDemo}
            className={`${
              isLunaBranded
                ? 'font-noto-sans bg-black'
                : 'btn-secondary font-Montserrat'
            } border-0 ${isLunaBranded ? 'rounded-md' : 'rounded'}
            w-25 sm:w-40 h-10 text-white text-sms font-bold flex items-center justify-center`}
          >
            Book a Demo
          </a>
          <Link
            to='/learning/assistant'
            onClick={handleTryFree}
            className={`${
              isLunaBranded
                ? 'font-noto-sans bg-luna-primary text-black'
                : 'btn-secondary bg-red-001 font-Montserrat text-white'
            } border-0 ${isLunaBranded ? 'rounded-md' : 'rounded'}
            w-25 sm:w-40 h-10 text-sms font-bold flex items-center justify-center`}
          >
            Try it free
          </Link>
        </div>
      );
    }

    return (
      <div className='flex items-center justify-center gap-2'>
        <nav className='hidden sm:flex items-center gap-6 text-sms mr-2'>
          <Link
            to='/learning/about'
            className='text-white hover:text-gray-300 font-medium'
          >
            About Us
          </Link>
          <Link
            to='/learning/pricing'
            className='text-white hover:text-gray-300 font-medium'
          >
            Pricing
          </Link>
        </nav>
        <Link
          to='/learning'
          className={`${
            isLunaBranded
              ? 'font-noto-sans bg-luna-primary text-black'
              : 'btn-secondary bg-red-001 font-Montserrat text-white'
          } border-0 ${isLunaBranded ? 'rounded-md' : 'rounded'}
          w-25 sm:w-40 h-10 text-sms font-bold flex items-center justify-center`}
        >
          Learning Home
        </Link>
      </div>
    );
  };

  return (
    <header
      className={`
        w-full flex-none bg-black border-b border-secondary 
        flex items-center justify-center text-white px-4 lg:px-7.5 py-2
      `}
    >
      <div className='flex items-center justify-between w-full h-full'>
        <div className='h-full flex items-center gap-7.5 text-sms'>
          <a href='https://lunapark.com/lp/luna-park-learning'>
            {isLunaBranded ? (
              <LunaLogo className='h-auto w-25 mr-4' />
            ) : (
              <LunaParkLogo className='w-27 h-7.5 mr-4' />
            )}
          </a>
        </div>
        {renderButtons()}
      </div>
    </header>
  );
}
