import { cn } from '../../../../utils/cn';

interface TestimonialCardProps {
  avatarUrl: string;
  name: string;
  position: string;
  company: string;
  companyLogoUrl: string;
  quote: string;
  className?: string;
}

export function TestimonialCard({
  avatarUrl,
  name,
  position,
  company,
  companyLogoUrl,
  quote,
  className,
}: TestimonialCardProps) {
  return (
    <div
      className={cn(
        'relative rounded-3xl bg-black p-8 shadow-lg flex flex-col justify-center',
        className
      )}
    >
      <div className='py-8'>
        <blockquote className='text-4xl font-bold text-white mb-8'>
          "{quote}"
        </blockquote>
        <div className='flex flex-col md:flex-row md:items-center md:justify-between'>
          <div className='flex items-center gap-4'>
            <img
              src={avatarUrl || '/placeholder.svg'}
              alt={`${name}'s profile`}
              width={48}
              height={48}
              className='rounded-full'
            />
            <div>
              <h3 className='font-bold text-lg text-icon-gray'>{name}</h3>
              <p className='text-icon-gray text-xs'>
                {position} @ {company}
              </p>
            </div>
          </div>
          <div className='hidden md:block'>
            <img
              src={companyLogoUrl || '/placeholder.svg'}
              alt={`${company} logo`}
              width={150}
              height={48}
              className='object-contain ml-2'
            />
          </div>
        </div>
        <div className='block md:hidden mt-4'>
          <img
            src={companyLogoUrl || '/placeholder.svg'}
            alt={`${company} logo`}
            width={150}
            height={64}
            className='object-contain'
          />
        </div>
      </div>
    </div>
  );
}
